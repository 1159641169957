<template>
  <ConfirmDialog
    :value.sync="show"
    positiveButtonText="Zapisz"
    :positiveAction="edit"
    :loading="loading"
    title="Zmień wpis"
    persistent
  >
    <v-layout column slot="content">
      <v-form ref="editForm" @submit.prevent="edit">
        <LabelTextField
          label="Tytuł"
          :rules="textRules"
          :value.sync="internalField.title"
          :hideDetails="internalField.title.length > 255"
        >
        </LabelTextField>
        <MaxlengthErrorMessage
          class="mx-4 mb-4"
          :value="internalField.title"
          :maxlength="255"
        ></MaxlengthErrorMessage>
        <LabelTextArea
          label="Nazwa"
          :rules="[...textRules]"
          :value.sync="internalField.text"
          :hideDetails="internalField.text.length > 20000"
        ></LabelTextArea>
        <MaxlengthErrorMessage
          class="mx-4"
          :value="internalField.text"
          :maxlength="2000"
        ></MaxlengthErrorMessage>
      </v-form>
    </v-layout>
    <v-layout column slot="footer" class="mx-3">
      <v-flex v-if="messageType != 0 && showMessage" shrink>
        <v-fade-transition>
          <Message
            :type="messageType"
            :text="message"
            @close="hideMessage"
          ></Message>
        </v-fade-transition>
      </v-flex>
      <v-layout column :class="[messageType != 0 && showMessage ? 'mt-2' : '']">
        <DefaultButton
          @click.prevent="edit"
          :loading="loading"
          :disabled="
            internalField.text.length > 20000 ||
            internalField.title.length > 255
          "
          class="mx-1 mb-1"
          primary
          >Zmień</DefaultButton
        >
        <DefaultButton
          @click.prevent="hidePopup"
          class="mx-1 mb-1"
          bgColor="error"
          >Anuluj</DefaultButton
        >
      </v-layout>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
import CRUDMixin from "@/mixins/CRUDMixin";
import Validators from "@/mixins/Validators";
import PredefinedService from "@/services/predefined.service";

export default {
  mixins: [Validators, CRUDMixin],
  props: {
    value: {},
    field: {},
    record: {},
    cardType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pagination: {
        sortBy: "title",
        descending: true,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    internalField: {
      get() {
        return this.record;
      },
      set(value) {
        this.$emit("update:record", value);
      },
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    LabelTextArea: () => import("@/components/LabelTextArea"),
    LabelTextField: () => import("@/components/LabelTextField"),
    Message: () => import("@/components/Message"),
    MaxlengthErrorMessage: () =>
      import("@/components/controls/MaxlengthErrorMessage"),
  },
  methods: {
    handleSelectedTemplate(template) {
      this.internalField.title = template.title;
      this.internalField.text = template.text;
    },
    hidePopup() {
      this.show = false;
    },
    edit() {
      if (!this.$refs.editForm.validate()) {
        return;
      }
      this.beforeRequest();
      PredefinedService.updatePredefinedRecord(this.internalField, {
        card_type: this.cardType,
        ...this.pagination,
      })
        .then(() => {
          this.reset();
          this.$emit("success", "Pomyślnie zapisano wszystkie zmiany.");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.handleError("Coś poszło nie tak.");
        });
    },
  },
};
</script>